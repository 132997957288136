<template>
	<div>
		<vx-card>
			<div class="vx-row">
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Domains*</label>
					<div v-if="unsuspensionDomains.length != 0">
						<template v-if="formData['domains'].length">
							<vs-chip @click="removeDomain(item)" v-for="item in formData['domains']" :key="item['domain_id']" closable>{{ item['domain'] }}</vs-chip>
						</template>
						<template v-else>
							<vs-chip>No domains selected</vs-chip>
						</template>
					</div>
					<vs-textarea v-if="unsuspensionDomains.length == 0" class="w-full" v-model="formData['domainsManual']" />
				</div>
				<div class="vx-col w-full mb-2">
					<label class="vs-input--label">Notes</label>
					<vs-textarea class="w-full" v-model="formData['notes']" />
				</div>
			</div>
			<div class="vx-row">
				<div class="vx-col w-full mt-4">
					<vs-button ref="loadableButton" id="button-with-loading" class="mb-2" @click="openConfirm()" :disabled="user_role != 'admin' || (formData['domains'].length == 0 && formData['domainsManual'] == '')">Submit</vs-button>
				</div>
			</div>
		</vx-card>
	</div>
</template>

<script>

	export default{
		data() {
			return {
				formData: {
					notes: '',
					domains: [],
					domainsManual: ''
				},
			}
		},
		watch: {
			
		},
		computed: {
			unsuspensionDomains: function(){
				return this.$store.getters.getUnsuspensionDomains;
			},
			user_role() {
				return JSON.parse(localStorage.getItem('userDetails')).role;
			},
		},
		methods: {
			showButtonLoading(){
				this.$vs.loading({
					background: 'primary',
					color: '#FFFFFF',
					container: '#button-with-loading',
					scale: 0.45
				});
			},
			hideButtonLoading(){
				this.$vs.loading.close("#button-with-loading > .con-vs-loading");
			},
			openConfirm() {
				// Open confirm popup
				this.$vs.dialog({
					type: 'confirm',
					color: 'primary',
					title: 'Confirm',
					text: 'Unsuspend the selected domains?',
					accept: this.unsuspendDomains
				});
			},
			unsuspendDomains() {
				let self = this,
					postURL;
				// Show loader
				this.showButtonLoading();
				// Assign Post URL
				if(this.formData['domainsManual'] != '') {
					postURL = '/unsuspend-domains-manual';
				}
				else {
					postURL = '/unsuspend-domains';
				}
				// Post Axios Call
				this.$axiosSecure.post(postURL, {
					...self.formData,
					watchtower_email: JSON.parse(localStorage.getItem('userDetails')).email
				})
				.then((response) => {
					if (response.data.errorArray.length) {
						// Open info popup
						self.$vs.dialog({
							type: 'confirm',
							color: 'primary',
							title: 'Please Note',
							text: 'The following domains were erroneous & hence ignored: '+response.data.errorArray.join(', '),
							accept: self.showUnsuspendSuccess,
							acceptText: 'Ok',
							buttonCancel: 'hidden'
						});
					}
					else {
						// Success Confirmation
						self.showUnsuspendSuccess();
					}
				})
				.catch((error) => {
					// Hide loader
					this.hideButtonLoading();
					// Error notification
					self.$vs.notify({
						color: 'danger',
						title: 'Something went wrong',
						text: 'Please contact the server admin'
					});

					console.log(error);
				});
			},
			showUnsuspendSuccess() {
				// Confimation notification
				this.$vs.notify({
					color: 'success',
					title: 'Unsuspension success',
					text: 'The selected domains have been queued for unsuspension'
				});
				
				// Hide loader
				this.hideButtonLoading();
				this.$router.push({ path: '/action-review' });
			},
			removeDomain(item) {
				this.removeFromArray(this.formData['domains'], item, 'domain_id');
			}
		},
		mounted() {
			// Prepopulate form
			this.formData['domains'] = this.unsuspensionDomains.map(x => x);
		},
		destroyed() {
			// Clear Unsuspension Domains
			this.$store.dispatch("clearUnsuspensionDomains");
		}
	}
</script>
